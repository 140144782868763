body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  overflow: -moz-scrollbars-none;
}
/* body::-webkit-scrollbar {
  width: 0 !important;
} */
@font-face {
  font-family: 'anton';
  src: url('assets/font/Anton-Regular.ttf');
}
@font-face {
  font-family: 'Insaniburger with Cheese';
  src: url('assets/font/Insanibc.ttf');
}
@font-face {
  font-family: 'Albert Sans-Bold, Albert Sans';
  src: url('assets/font/MilkyNice.ttf');
}
@font-face {
  font-family: 'Microsoft Tai Le';
  src: url('assets/font/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Microsoft Tai Le';
  src: url('assets/font/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Albert Sans-Bold';
  src: url('assets/font/AlbertSans-Bold.ttf');
}

@font-face {
  font-family: 'Albert Sans-Black';
  src: url('assets/font/AlbertSans-Black.ttf');
}

@font-face {
  font-family: 'Albert Sans-Medium';
  src: url('assets/font/AlbertSans-Medium.ttf');
}

@font-face {
  font-family: 'AvenueX-Regular';
  src: url('assets/font/AvenueX-Regular.otf');
}

@font-face {
  font-family: 'Chilling Sabrina-Regular';
  src: url('assets/font/ChillingSabrina.ttf');
}

@font-face {
  font-family: 'CRSL Arches-Regular';
  src: url('assets/font/CRSL-Arches-Regular.otf');
}

@font-face {
  font-family: 'Milky Nice';
  src: url('assets/font/MilkyNice.ttf');
}
