html {
  font-size: 62.5%;
}
html body #root .app {
  min-height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color:rgba(0,0,0,0); 
}
.grecaptcha-badge { 
	display: none; 
} 